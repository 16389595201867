$dark: #212529;
$red: #f61a42;
$rausch: #ff5a5f;
$green: #73cb8a;
$pink: #ea4aaa;
$grey: #ebeef5;
$blue: #007bff;
$beautiful: #2d3748;

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 Christian Aichner
 */
