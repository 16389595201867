@import "./variables";
@import "./fonts";

html,
body {
  height: 100%;
  background: $grey;
  font-family: "Poppins", "Roboto", sans-serif;
}

#root {
  height: 100%;
}

.row {
  width: 100%;
  padding: 0;
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex: 1;
}

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: unset !important;
  box-shadow: none;
}

.page-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// Custom colors
.bg-dark {
  background-color: $dark !important;

  &.darken-1 {
    background-color: darken($dark, 5%) !important;
  }

  &.darken-2 {
    background-color: darken($dark, 10%) !important;
  }

  &.darken-3 {
    background-color: darken($dark, 15%) !important;
  }

  &.lighten-1 {
    background-color: lighten($dark, 10%) !important;
  }

  &.lighten-2 {
    background-color: lighten($dark, 20%) !important;
  }

  &.lighten-3 {
    background-color: lighten($dark, 30%) !important;
  }
}

.bg-red {
  background-color: $red !important;

  &.darken-1 {
    background-color: darken($red, 10%) !important;
  }

  &.darken-2 {
    background-color: darken($red, 20%) !important;
  }

  &.darken-3 {
    background-color: darken($red, 30%) !important;
  }

  &.lighten-1 {
    background-color: lighten($red, 10%) !important;
  }

  &.lighten-2 {
    background-color: lighten($red, 20%) !important;
  }

  &.lighten-3 {
    background-color: lighten($red, 30%) !important;
  }
}

.bg-green {
  background-color: $green !important;

  &.darken-1 {
    background-color: darken($green, 10%) !important;
  }

  &.darken-2 {
    background-color: darken($green, 20%) !important;
  }

  &.darken-3 {
    background-color: darken($green, 30%) !important;
  }

  &.lighten-1 {
    background-color: lighten($green, 10%) !important;
  }

  &.lighten-2 {
    background-color: lighten($green, 20%) !important;
  }

  &.lighten-3 {
    background-color: lighten($green, 30%) !important;
  }
}

// Custom text
.red-text {
  color: $red !important;
}

.pink-text {
  color: $pink !important;
}

// Badge
.badge {
  box-shadow: none !important;
  border-radius: 1rem;
  padding: 0.35rem 0.8rem;
  //margin: 0 !important;

  &.badge-grey {
    background: white !important;
    border: 2px solid lightgrey;
    color: darken(lightgrey, 10%) !important;
  }

  &.badge-green {
    border: 2px solid #4caf50;
  }
}

// Buttons
.btn {
  box-shadow: none !important;
  border-radius: 2rem;
  text-transform: none;

  &.btn-grey {
    background: $dark !important;
    color: white;

    &.selected {
      background: lighten($dark, 10%) !important;
      color: white;
    }

    &:hover {
      background: lighten($dark, 10%) !important;
    }

    &:not([disabled]):not(.disabled):active {
      background-color: lighten($dark, 15%) !important;
    }
  }

  &.btn-default {
    background: white !important;
    border: 2px solid black;
    color: black;

    &.selected {
      border: 2px solid #1d1d1d !important;
      background: darken(white, 5%) !important;
      color: #1d1d1d;
    }

    &:hover {
      background: darken(white, 3%) !important;
    }

    &:not([disabled]):not(.disabled):active {
      background-color: darken(white, 7%) !important;
    }
  }

  &.btn-black {
    background: black !important;
    border: 2px solid black;
    color: white;

    &.selected {
      border: 2px solid lighten(black, 20%) !important;
      background: white !important;
      color: black;

      &:hover {
        background: darken(white, 10%) !important;
      }
    }

    &:hover {
      background: lighten(black, 20%) !important;
      border: 2px solid lighten(black, 20%) !important;
    }

    &:not([disabled]):not(.disabled):active {
      background-color: lighten(black, 30%) !important;
    }
  }

  &.btn-green {
    background: white !important;
    border: 2px solid $green;
    color: $green;

    &.selected {
      border: 2px solid #1d1d1d !important;
      background: lighten($green, 30%) !important;
      color: #1d1d1d;
    }

    &:hover {
      background: lighten($green, 35%) !important;
    }

    &:not([disabled]):not(.disabled):active {
      background-color: lighten($green, 30%) !important;
    }
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

// Cards
.card {
  box-shadow: none;
}

// Generic
.clickable {
  cursor: pointer !important;
}

// Fix icon spacing in buttons
.btn {
  i {
    padding-right: 0.2rem;
  }
}

.alert {
  font-size: 0.9rem;

  &.alert-danger {
    background: unset !important;
    color: $red !important;
    border-color: $red !important;

    i {
      margin-right: 0.3rem;
    }
  }
}

.gilroy {
  font-family: Gilroy, "Roboto", sans-serif;
}

.splitter {
  overflow: hidden;
  text-align: center;

  .or {
    position: relative !important;
    padding-bottom: var(--spacing-rule-text-vertical, 16px) !important;
    padding-top: var(--spacing-rule-text-vertical, 16px) !important;
    padding-left: var(--spacing-rule-text-horizontal, 16px) !important;
    padding-right: var(--spacing-rule-text-horizontal, 16px) !important;

    .or-text {
      overflow-wrap: break-word !important;
      margin: 0 !important;

      &::before {
        border-bottom-width: var(--border-rule-border-width, 1px) !important;
        border-bottom-color: #e4e4e4 !important;
        content: "" !important;
        position: absolute !important;
        border-bottom-style: solid !important;
        top: 50% !important;
        right: 100% !important;
        width: 5000px !important;
      }

      &::after {
        border-bottom-width: var(--border-rule-border-width, 1px) !important;
        border-bottom-color: #e4e4e4 !important;
        content: "" !important;
        position: absolute !important;
        border-bottom-style: solid !important;
        top: 50% !important;
        left: 100% !important;
        width: 5000px !important;
      }
    }
  }
}

.animation-container {
  width: 400px;
  height: auto;
  max-width: 80%;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
  border-width: 4px;
  border-radius: 0.4rem;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  height: 100%;

  p {
    margin-bottom: 0;
  }

  &:hover {
    border-color: darken(#eeeeee, 10%);
  }

  &.active {
    border-color: #2196f3;
  }

  &.accept {
    border-color: #00e676;
  }

  &.reject {
    border-color: #ff1744;
  }
}

.thumbs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;

  .thumb {
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    margin-bottom: 8;
    margin-right: 8;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;

    .thumb-inner {
      display: flex;
      min-width: 0;
      overflow: hidden;

      .view {
        cursor: pointer;

        .mask {
          transition: all 0.2s ease;
        }

        &:hover {
          .mask {
            background: rgba(255, 0, 0, 0.2) !important;
          }
        }
      }

      img {
        display: block;
        width: auto;
        height: 100%;
      }
    }
  }
}

// Lock
.lock-message {
  padding: 1rem;
  text-align: center;
  border-radius: 2rem;
  background: transparentize($color: white, $amount: 0.2) !important;
}

// Button icon placement
.btn {
  i {
    margin-right: 0.2rem;
  }

  svg {
    margin-right: 0.2rem;
  }
}

// Table item alignment
table.table th,
table.table td {
  vertical-align: middle;
}

// Checkbox
.form-check-input[type="checkbox"].filled-in:checked + label:after,
label.btn input[type="checkbox"].filled-in:checked + label:after {
  background-color: $dark;
  border: 2px solid $dark;
  top: 10px;
}

.form-check-input[type="checkbox"].filled-in:not(:checked) + label:after,
label.btn input[type="checkbox"].filled-in:not(:checked) + label:after {
  top: 10px;
}

.form-check-input[type="checkbox"].filled-in:checked + label:before,
label.btn input[type="checkbox"].filled-in:checked + label:before {
  top: 10px;
}

.custom-input {
  p {
    &:not(.text-center) {
      padding-left: 1rem;
    }

    .text-muted {
      color: lightgrey !important;
    }
  }

  input {
    border-radius: 2rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.light {
  background: $grey;
  transition: all 0.2s ease;

  &:hover {
    background: darken($grey, 3%);
  }
}

// Modal
.modal {
  max-height: 100vh;
  height: auto;
  overflow-y: scroll;

  &.full-height {
    max-height: initial;
    height: 100% !important;
  }
}

.text-muted {
  color: #6c757d !important;
  transition: all 0.2s ease;

  &.clickable {
    &:hover {
      color: lighten(#6c757d, 20%) !important;
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 Christian Aichner
 */
